.login-layout__container {
  /* min-height: 100vh;
  min-height: 100svh; */
  max-height: 100vh;
  max-height: 100svh;
  max-width: 100vw;

  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto;
}

.login-layout__nav {
  margin-inline: 2rem;
  margin-block: 1rem;
}

.login-layout__content {
  width: 100%;
  display: grid;
  grid-template: ". login image" 100% / 20% 30% 50%;
}

.login-layout__content-inner {
  grid-area: login;
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .login-layout__content {
    grid-template: ". login ." 100% / 10% 80% 10%;
  }
  .login-layout__content-inner {
    padding-inline: 1em;
  }
  .login-layout__image {
    grid-area: login;
  }
}

@media all and (max-width: 1300px) {
  .background-container {
    display: none;
  }
}

.login-layout__image {
  margin-top: -20%;
  grid-area: image;
  align-self: start;
  justify-self: end;
  z-index: -1;
  width: 100%;
  object-fit: contain;
  object-position: bottom right;
}

.login-layout__footer {
  display: flex;
  flex-direction: row;
  /* :( temporary fix that will no doubt be here forever */
  justify-content: flex-end !important;
}

.login__input {
  width: 100%;
  padding: 1em;
  background-color: #f0f3f5;
}

.login__column h1,
.login__column button {
  margin-block: 1rem;
}

.register-btn {
  min-width: 8.5rem;
  min-height: 3.2rem;
}

@media all and (max-width: 400px) {
  .home {
    display: none;
  }

  .logo {
    position: relative;
    left: -12px;
  }
}
