#tooltip {
  position: relative;
}

#tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 100%; /* Place the tooltip above the icon */
  left: 50%;
  margin-left: -130px; /* Use half the width to center the tooltip */

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

#tooltip:hover #tooltiptext {
  visibility: visible;
  opacity: 1;
}
